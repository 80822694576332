import React from 'react';

import PropTypes from 'prop-types';
import reduxForm from 'redux-form/lib/reduxForm';
import Form from 'reactstrap/lib/Form';
import { toast } from 'react-toastify';
import Field from 'redux-form/lib/Field';
import CheckboxAtom from 'atoms/Checkbox';
import { FORM_LAYOUT } from 'constants/layout';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import cookie from 'react-cookies';
import styles from './styles.module.scss';
import { ListGroup, ListGroupItem} from 'reactstrap';

/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection -> ExportModal
 *
 * Props:
 *    - update
 */
class ExportModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectStandardFormat = this.selectStandardFormat.bind(this);
    this.state = { selectedFormat : null, selectedButtonLoading: null };
    this.submit = this.submit.bind(this);
  }

  componentWillMount(){
    const cookieKey = cookie.load("exportSelectedFormat");
    if(cookieKey){
      this.setState({ selectedFormat: cookieKey });
    }
  }

  submit(data){
    const { exportCsv, exportPdfandXls, exportJobPayroll, exportADP, dates, exportPayDatacsv, exportmileagecsv, exportSouthEast, exportJobPayrollDetail, exportSelectedFile, exportSage, exportiSolved, exportMikeRovnerCsv ,exportDataTech,exportIif,exportMealsFlagExport, exportFenceFactory, exportPtoBalance, exportPaylocity, famousExport} = this.props;
    const { selectedFormat } = this.state;
    if(!selectedFormat) return toast.error('Please select the file type to export');
    if(selectedFormat === 'csv'){
      return exportCsv(dates);
    }
    else if (selectedFormat === 'dataTechcsv'){
      const format = 'csv';
      return exportDataTech(format);
    } 
    else if (selectedFormat === 'iif' || selectedFormat === 'iif2'){
      return exportIif(selectedFormat);
    }
    else if (selectedFormat === 'jobCostingXls' || selectedFormat === 'jobCostingPdf' || selectedFormat === 'jobCostingPdf2'){
      const format = selectedFormat === 'jobCostingXls' ? 'xlsx' : selectedFormat === 'jobCostingPdf2' ? 'pdf2' : 'pdf';
      return exportJobPayroll(format, dates);
    }
    else if (selectedFormat === 'jobCostingDetailXls' || selectedFormat === 'jobCostingDetailPdf' || selectedFormat === 'jobCostingDetailPdf2'){
      const format = selectedFormat === 'jobCostingDetailXls' ? 'xlsx' : selectedFormat === 'jobCostingDetailPdf2' ? 'pdf2' : 'pdf';
      return exportJobPayrollDetail(format, dates);
    }
    else if (selectedFormat === 'ADPXls' || selectedFormat === 'ADPXls2'){
      return exportADP(selectedFormat);
    }
    else if (selectedFormat === 'payDatacsv'){
      return exportPayDatacsv();
    }
    else if (selectedFormat === 'mileagecsv'){
      return exportmileagecsv();
    }
    else if (selectedFormat === 'southEastCsv'){
      return exportSouthEast();
    }
    else if (selectedFormat === 'auditXls' || selectedFormat === 'auditXls2' || selectedFormat === 'adjustmentXls'){
      return exportSelectedFile(selectedFormat);
    }
    else if (selectedFormat === 'sageExportcsv'){
      return exportSage();
    }
    else if(selectedFormat === 'iSolvedCsv'){
      const format = 'csv';
      return exportiSolved(format, dates);
    }
    else if(selectedFormat === 'mikerovnercsv'){
      const format = 'csv';
      return exportMikeRovnerCsv(format, dates);
    }else if(selectedFormat === 'mealsFlagExport'){
      const format = 'csv';
      return exportMealsFlagExport(format, dates);
    }else if(selectedFormat === 'fenceFactoryXls'){
      const format = 'xlsx';
      return exportFenceFactory(format, dates);
    }else if(selectedFormat === 'ptoBalanceCsv'){
      return exportPtoBalance();
    }else if(selectedFormat === 'paylocityCsv'){
      return exportPaylocity();
    }
    else if(selectedFormat === 'famousexporttxt'){
      return famousExport();
    }
    return exportPdfandXls(selectedFormat, dates);
  }

  selectStandardFormat(data){
    this.setState({ selectedFormat : data, selectedButtonLoading: data}, () => {this.submit(this.state.selectedFormat)});
    cookie.save("exportSelectedFormat", data);
  }

  render() {
    const { isOpen,
      toggle, handleSubmit,userTimecardFilters ,userExtraData} = this.props;
    const { selectedFormat, selectedButtonLoading } = this.state;
   
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Timecard Exports</ModalHeader>
        <Form noValidate onSubmit={handleSubmit(this.submit)} className="mt-0">
          <ModalBody >
            <ListGroup flush className={styles['export-footer']}>
              {/*employee Timecards ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50">
                  <h6 className="mb-0 mt-0">Employee Timecards</h6>
                </span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('pdf')}
                    active={selectedFormat === 'pdf'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT PDF <ButtonLoaderAtom active={selectedButtonLoading === 'pdf'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('xls')}
                    active={selectedFormat === 'xls'}
                    className={`${styles['export-button-custom-width']} mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'xls'} /></small></span>
                  </Button>
                </span>
                <div className="w-50">
                  <div className='checkbox-custom-position'>
                    <Field
                      id="correction_request"
                      type="checkbox"
                      name="correction_request"
                      label= "Include Correction Requests"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('correction_request')}
                    />
                  </div>
                  <div className='checkbox-custom-position'>
                    <Field
                      id="days_not_worked"
                      type="checkbox"
                      name="days_not_worked"
                      label="Include Days Not Worked"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('days_not_worked')}
                    />
                  </div>
                  <div className='checkbox-custom-position'>
                    <Field
                      id="employee_shift_available"
                      type="checkbox"
                      name="employee_shift_available"
                      label="Include Shift Name"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('job_name')}
                    />
                  </div>
                  <div className='checkbox-custom-position'>
                    <Field
                      id="organization_information"
                      type="checkbox"
                      name="organization_information"
                      label="Include Org Info"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('org_info')}
                    />
                  </div>
                  {userTimecardFilters && userTimecardFilters.data ? ((userTimecardFilters.data.timecard_decimal_view == "true" || userTimecardFilters.data.timecard_decimal_view == true) ? cookie.save('isHideDecimalConversion', false):<div className='checkbox-custom-position'>
                    <Field
                      id="disable_decimal_row"
                      type="checkbox"
                      name="disable_decimal_row"
                      label="Hide Decimal Conversion Totals"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('disable_decimal_row')}
                    />
                  </div> ) :null}
                  {userTimecardFilters?.data?.timecard_decimal_view === "true" &&
                    <div className='checkbox-custom-position'>
                    <Field
                      id="disable_hh_mm_row"
                      type="checkbox"
                      name="disable_hh_mm_row"
                      label="Hide HH:MM Conversion Totals"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('disable_hh_mm_row')}
                    />
                </div>
                  }

                 <div className='checkbox-custom-position'>
                    <Field
                      id="include_page_number"
                      type="checkbox"
                      name="include_page_number"
                      label="Include Page Number"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('include_page_number')}
                    />
                </div>

                <div className='checkbox-custom-position'>
                    <Field
                      id="add_supervisor_signature"
                      type="checkbox"
                      name="add_supervisor_signature"
                      label="Add Supervisor Signature"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      onChange={()=>this.props.toggleEmployeeCheckbox('add_supervisor_signature')}
                    />
                </div>
                  
                </div>
              </ListGroupItem>

              {/*ADP ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">ADP</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('ADPXls2')}
                    active={selectedFormat === 'ADPXls2'}
                    className={`${styles['export-button-custom-width']}  float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV 2<ButtonLoaderAtom active={selectedButtonLoading === 'ADPXls2'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('ADPXls')}
                    active={selectedFormat === 'ADPXls'}
                    className={`${styles['export-button-custom-width']}  mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>Export CSV <ButtonLoaderAtom active={selectedButtonLoading === 'ADPXls'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/* Adjustment Summary */}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Adjustment Summary</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('adjustmentXls')}
                    active={selectedFormat === 'adjustmentXls'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'adjustmentXls'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/* Audit Summary */}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Audit Summary</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('auditXls2')}
                    active={selectedFormat === 'auditXls2'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS 2<ButtonLoaderAtom active={selectedButtonLoading === 'auditXls2'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('auditXls')}
                    active={selectedFormat === 'auditXls'}
                    className={`${styles['export-button-custom-width']}  mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'auditXls'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*Data Tech ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Data Tech</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('dataTechcsv')}
                    active={selectedFormat === 'dataTechcsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'dataTechcsv'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              
              {/*Fence Factory*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Fence Factory</h6></span>
                <span className="w-50">
                <Button
                  outline color="primary"
                  onClick={() => this.selectStandardFormat('fenceFactoryXls')}
                  active={selectedFormat === 'fenceFactoryXls'}
                  className={`${styles['export-button-custom-width']} float-right`}
                >
                  <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'fenceFactoryXls'} /></small></span>
                </Button>
                </span>
              </ListGroupItem>

              {/*iSolved ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">iSolved</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('iSolvedCsv')}
                    active={selectedFormat === 'iSolvedCsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'iSolvedCsv'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*Jobcosting Payroll Detail ListGroupItem*/}
              <ListGroupItem>
                <span className="mr-auto">
                  <h6 className="mb-3">Job Costing Payroll Detail</h6>
                  <div className='checkbox-custom-position'>
                    <Field
                      id="corrections_available"
                      type="checkbox"
                      name="corrections_available"
                      label="Include Corrections"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      checked={this.props.jobCosting === 'corrections_available' }
                      onChange={()=>this.props.toggleJobCorrectionCheckbox("corrections_available")}
                  />
                   <Field
                      id="notes_available"
                      type="checkbox"
                      name="notes_available"
                      label="Include Job Notes"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      checked={this.props.jobCosting === 'notes_available' }
                      onChange={()=>this.props.toggleJobCorrectionCheckbox("notes_available")}
                  />
                  </div>
                </span>
                <span>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('jobCostingDetailPdf2')}
                    active={selectedFormat === 'jobCostingDetailPdf2'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT PDF 2<ButtonLoaderAtom active={selectedButtonLoading === 'jobCostingDetailPdf2'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('jobCostingDetailPdf')}
                    active={selectedFormat === 'jobCostingDetailPdf'}
                    className={`${styles['export-button-custom-width']} mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT PDF <ButtonLoaderAtom active={selectedButtonLoading === 'jobCostingDetailPdf'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('jobCostingDetailXls')}
                    active={selectedFormat === 'jobCostingDetailXls'}
                    className={`${styles['export-button-custom-width']} mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'jobCostingDetailXls'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*Jobcosting Payroll summary ListGroupItem*/}
              <ListGroupItem>
                <span className='mr-auto'><h6 className="mb-0">Job Costing Payroll Summary</h6></span>
                <span>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('jobCostingPdf2')}
                    active={selectedFormat === 'jobCostingPdf2'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT PDF 2 <ButtonLoaderAtom active={selectedButtonLoading === 'jobCostingPdf2'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('jobCostingPdf')}
                    active={selectedFormat === 'jobCostingPdf'}
                    className={`${styles['export-button-custom-width']} mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT PDF <ButtonLoaderAtom active={selectedButtonLoading === 'jobCostingPdf'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('jobCostingXls')}
                    active={selectedFormat === 'jobCostingXls'}
                    className={`${styles['export-button-custom-width']} mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'jobCostingXls'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/* Meal Survey Export */}
              {
                userExtraData?.company_permissions?.meal_questionnaire &&
                <ListGroupItem>
                    <span className="w-50"><h6 className="mb-0">Meal Survey Export</h6></span>
                    <span className="w-50">
                      <Button
                        outline color="primary"
                        onClick={() => this.selectStandardFormat('mealsFlagExport')}
                        active={selectedFormat === 'mealsFlagExport'}
                        className={`${styles['export-button-custom-width']} float-right`}
                      >
                        <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'mealsFlagExport'} /></small></span>
                      </Button>
                    </span>
                </ListGroupItem>
              }

              {/*Paychex ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Paychex</h6></span>
                <span className="w-50">
                   <Button
                  outline color="primary"
                  onClick={() => this.selectStandardFormat('csv')}
                  active={selectedFormat === 'csv'}
                  className={`${styles['export-button-custom-width']} float-right`}
                >
                  <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'csv'} /></small></span>
                </Button>
                </span>
              </ListGroupItem>

              {/* Paylocity */}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Paylocity</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('paylocityCsv')}
                    active={selectedFormat === 'paylocityCsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'paylocityCsv'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/* PTO */}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">PTO Balance</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('ptoBalanceCsv')}
                    active={selectedFormat === 'ptoBalanceCsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'ptoBalanceCsv'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*Quickbooks ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Quickbooks</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('iif2')}
                    active={selectedFormat === 'iif2'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT IIF 2<ButtonLoaderAtom active={selectedButtonLoading === 'iif2'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('iif')}
                    active={selectedFormat === 'iif'}
                    className={`${styles['export-button-custom-width']}  mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT IIF <ButtonLoaderAtom active={selectedButtonLoading === 'iif'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*SAGE EXPORT ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">SAGE</h6></span>
                <span className="w-50">
                   <Button
                  outline color="primary"
                  onClick={() => this.selectStandardFormat('sageExportcsv')}
                  active={selectedFormat === 'sageExportcsv'}
                  className={`${styles['export-button-custom-width']} float-right`}
                >
                  <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'sageExportcsv'} /></small></span>
                </Button>
                </span>
              </ListGroupItem>

              {/*SDP payroll ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">SDP Payroll</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('payDatacsv')}
                    active={selectedFormat === 'payDatacsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'payDatacsv'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*SDP Reimbursement ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">SDP Reimbursement</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('mileagecsv')}
                    active={selectedFormat === 'mileagecsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'mileagecsv'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*Southeast Payroll ListGroupItem*/}
              <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Southeast Payroll</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('southEastCsv')}
                    active={selectedFormat === 'southEastCsv'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'southEastCsv'} /> </small></span>
                  </Button>
                </span>
              </ListGroupItem>

              {/*Mike Rovner ListGroupItem*/}
              <ListGroupItem>
                  <span className="w-50"><h6 className="mb-0">Viewpoint</h6></span>
                  <span className="w-50">
                    <Button
                      outline color="primary"
                      onClick={() => this.selectStandardFormat('mikerovnercsv')}
                      active={selectedFormat === 'mikerovnercsv'}
                      className={`${styles['export-button-custom-width']} float-right`}
                    >
                      <span className="mt-2 ml-1"><small>EXPORT CSV <ButtonLoaderAtom active={selectedButtonLoading === 'mikerovnercsv'} /></small></span>
                    </Button>
                  </span>
              </ListGroupItem>

              <ListGroupItem>
                  <span className="w-50"><h6 className="mb-0">Famous Export</h6></span>
                  <span className="w-50">
                    <Button
                      outline color="primary"
                      onClick={() => this.selectStandardFormat('famousexporttxt')}
                      active={selectedFormat === 'famousexporttxt'}
                      className={`${styles['export-button-custom-width']} float-right`}
                    >
                      <span className="mt-2 ml-1"><small>EXPORT ASCII<ButtonLoaderAtom active={selectedButtonLoading === 'famousexporttxt'} /></small></span>
                    </Button>
                  </span>
              </ListGroupItem>
          

             {/*Data Tech Export
             <ListGroupItem>
                <span className="w-50"><h6 className="mb-0">Data Tech</h6></span>
                <span className="w-50">
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('dataTechPdf')}
                    active={selectedFormat === 'dataTechPdf'}
                    className={`${styles['export-button-custom-width']} float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT PDF <ButtonLoaderAtom active={selectedButtonLoading === 'dataTechPdf'} /></small></span>
                  </Button>
                  <Button
                    outline color="primary"
                    onClick={() => this.selectStandardFormat('dataTechXls')}
                    active={selectedFormat === 'dataTechXls'}
                    className={`${styles['export-button-custom-width']} mr-3 float-right`}
                  >
                    <span className="mt-2 ml-1"><small>EXPORT XLS <ButtonLoaderAtom active={selectedButtonLoading === 'dataTechXls'} /></small></span>
                  </Button>
                </span>
              </ListGroupItem>
            */}
              
            </ListGroup>
           
          </ModalBody>

          {/*<ModalFooter>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
              Export Now <ButtonLoaderAtom active={submitting} />
            </Button>
          </ModalFooter>*/}
        </Form>
      </Modal>
    );
  }
};


ExportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ExportFormModal',
  enableReinitialize: true,
})(ExportModal);

