import React from "react";
import queryString from "query-string";
import cookie from "react-cookies";
import connect from "react-redux/lib/connect/connect";
import PropTypes from "prop-types";
import moment from "moment";

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as UserDucks from 'ducks/accounts/user';
import bindActionCreators from 'redux/lib/bindActionCreators';
import EmployeeForm from 'employees/components/form';
import EmployeeJobsFilterFormComponent from 'employees/components/employeeJobsFilterForm';
import EmployeeKioskTableFilterComponent from 'employees/components/employeeKioskFilterForm'
import EmployeeJobsTableComponent from 'employees/components/employeesJobTable.jsx'
import PaginationComponent from 'components/common/Pagination';
import EmployeeSubheader from 'employees/components/subheader';
import EmployeeTimeOffTable from 'employees/components/EmployeeTimeOffTable'
import KioskTable from 'employees/components/KioskList';
import AuditTable from 'employees/components/AuditList';
import EmployeeFormUseCase from 'employees/usecases/formUseCase';
import EmployeeAPIGateway from 'employees/gateways/employee';
import TwoColumnLayout from 'layouts/TwoColumn';
import TabsComponent from 'components/common/Tabs';
import { Row, Col } from 'reactstrap';
import { NAV, TABS } from 'constants/employees';
import { toast } from 'react-toastify';
import ActivationLinkModal from 'employees/components/activationLinkModal';
import SectionLoaderAtom from 'atoms/SectionLoader';
import AddTimeOffSlider from 'sections/pto/addTimeOffSlider/TimeOffSlider';
import TimeOffRequestSlider from 'sections/pto/timeOffRequestSlider/TimeOffRequestSlider';
import ViewTimeOffPolicies from 'sections/pto/viewTimeOffPolicies/ViewTimeOffPolicies';
import ViewTimeOffPoliciesHistorySlider from "sections/employees/list/ViewTimeOffPoliciesHistorySlider";
import LayerAtom from "atoms/NewLayerModal";

import StorageGateway from "lib/storage-gateway";
import DocumentsTab from "employees/components/DocumentsTab";
import LearningDocumentsTab from "employees/components/LearningDocumentsTab";
import PtoWarningModal from 'components/common/PtoWarningModal';


import styles from "./styles.module.scss";

let oAuthToken = "";
let company_id = "";
let nre_token = "";
let nre_company_id = "";
let userID = "";
class EmployeeFormPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    const employeeAPIGateway = new EmployeeAPIGateway();
    this.usecase = new EmployeeFormUseCase(employeeAPIGateway);
    this.listenEmployeeFormUseCase = this.listenEmployeeFormUseCase.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.getLoginLink = this.getLoginLink.bind(this);
    this.toggle = this.toggle.bind(this);
    this.switchTabs = this.switchTabs.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.deleteEmployeeJob = this.deleteEmployeeJob.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.closeSlider = this.closeSlider.bind(this);
    this.toggleTimeOffRequestSlider = this.toggleTimeOffRequestSlider.bind(
      this
    );
    this.toggleViewTimeOffPoliciesSlider = this.toggleViewTimeOffPoliciesSlider.bind(
      this
    );
    this.toggleHistorySlider = this.toggleHistorySlider.bind(this);
    this.postMessageToChild=this.postMessageToChild.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.closePtoWarningModal = this.closePtoWarningModal.bind(this);
    this.handleDoNotShowMsgPto = this.handleDoNotShowMsgPto.bind(this);




    this.state = {
      data: {
        roleList: [],
        countryList: [],
        companyList: [],
        departmentList: [],
        teamList: [],
        stateList: [],
        infoList: [],
        loginLink: [],
      },
      employeeData: {},
      user_Id: null,
      isLoading: false,
      parent: NAV.EmployeeAddFormPage.parent,
      isAddInfoOpen: true,
      isEditInfoOpen: true,
      kioskList: [],
      selectedItem: null,
      isKioskLoading: true,
      showModal: false,
      settingsList: [],
      auditLogsList: [],
      isAuditLogsLoading: false,
      assignedJobs: {},
      unAssignedJobs: [],
      assignedJobscount: null,
      assignedStatus: true,
      isButtonLoading: false,
      employeeTagsList: null,
      phoneNumberPlain: null,
      addTimeOffSlider: false,
      timeOffRequestSlider: false,
      refrshDocTabFlag: false,
      viewTimeOffPoliciesSlider: false,
      historySlider: false,ptoWarningModal:false,
      ptoWarningMessage:"",
      policyName: "",
      policyId: "",
      employeeTimeOffRefresh: false,
      companyPoliciesList: null,
      policyName: "",
      refrshLearningDocTabFlag:false,
      toggleEmployeeNotes: false,
    };
  }


  closePtoWarningModal(){
    this.setState({ptoWarningModal:false});
  }

  async handleDoNotShowMsgPto(check){
    try {
      await this.props.UserDucks.putDontShowPTOWarningModal({userId: this.state.user_Id, check: check});
    } catch (error) {
      toast.error("Something went wrong!");
    }
    this.closePtoWarningModal();
  }

/**
 * @function eventListnerFunction
 * @description Listens for messages from other windows or frames, processes the message content,
 * and triggers different actions based on the message type.
 * @param {Object} event - The message event object.
 */
  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
     if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    } 
  }


  
/**
* @function componentWillMount
* @description Lifecycle method that runs before the component is mounted. It initializes data,
* loads cookies, and fetches employee data based on URL parameters.
*/
  componentWillMount() {
    const {
      match: { params },
    } = this.props;
    this.listenEmployeeFormUseCase();
    this.setParentUrl();
    if (params.id) {
      // this.usecase.getEmployeeData(params.id);
      this.fetchTabData(this.props);
      this.usecase.getEmployeeTags({ paginate: false, tag_type: 3 });
      this.usecase.getEmployeesPolicies({ paginate: false, is_active: true });
      const cookieKey = cookie.load("employee_edit_form_info");
      if (cookieKey) {
        this.updateState("isEditInfoOpen", JSON.parse(cookieKey));
      }
    } else {
      this.fetchData();
      this.usecase.getEmployeeTags({ paginate: false, tag_type: 3 });
      this.usecase.getEmployeesPolicies({ paginate: false, is_active: true });
      const cookieKey = cookie.load("employee_add_form_info");
      if (cookieKey) {
        this.updateState("isAddInfoOpen", JSON.parse(cookieKey));
      }
    }
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
    nre_token = StorageGateway.get("graniteToken");
    nre_company_id = StorageGateway.get("nre_company_id");
    userID = StorageGateway.get("userId");
  }


  
  /**
  * @function componentDidMount
  * @description Lifecycle method that runs after the component is mounted. Adds an event listener
  * for message events.
  */
  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
  }

  /**
  * @function componentWillUnmount
  * @description Lifecycle method that runs before the component is unmounted. Removes the event listener
  * for message events.
  */
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }





  /**
  * @function componentWillReceiveProps
  * @description Lifecycle method that is called when the component receives new props. Fetches
  * updated tab data if the route parameters change.
  * @param {Object} nextProps - The next props that the component will receive.
  */
  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match) {
      this.fetchTabData(nextProps);
    }
  }

  /**
  * @function closeSlider
  * @description Closes or opens the 'Add Time Off' slider based on the status passed.
  * @param {boolean} status - True to open the slider, false to close it.
  */
  closeSlider(status) {
    status
      ? this.setState({
          addTimeOffSlider: status,
          timeOffRequestSlider: false,
          viewTimeOffPoliciesSlider: false,
          historySlider: false,
        })
      : this.setState({ addTimeOffSlider: status });
  }

  /**
  * @function toggleTimeOffRequestSlider
  * @description Toggles the visibility of the 'Time Off Request' slider. When opened, other sliders
  * are closed.
  * @param {Object} values - Contains the status and policyId for the slider.
  */
  toggleTimeOffRequestSlider(values) {
    if (values.status) {
      this.setState({
        addTimeOffSlider: false,
        viewTimeOffPoliciesSlider: false,
        historySlider: false,
      });
    }
    this.setState({
      timeOffRequestSlider: values.status,
      policyId: values.policyId,
    });
  }

  /**
  * @function toggleHistorySlider
  * @description Toggles the visibility of the 'Accrual History' slider. Sets the policy details.
  * @param {Object} values - Contains the status and policy details for the slider.
  */
  toggleHistorySlider(values) {
    this.setState({
      historySlider: values.status,
      policyId: values.policy_id,
      policyName: values.policy_name,
      policyType:values.policy_type
    });
  }

  /**
  * @function toggleViewTimeOffPoliciesSlider
  * @description Toggles the visibility of the 'View Time Off Policies' slider. When opened, other sliders
  * are closed.
  * @param {boolean} status - True to open the slider, false to close it.
  */
  toggleViewTimeOffPoliciesSlider(status) {
    status
      ? this.setState({
          viewTimeOffPoliciesSlider: status,
          addTimeOffSlider: false,
          historySlider: false,
          timeOffRequestSlider: false,
        })
      : this.setState({ viewTimeOffPoliciesSlider: status });
  }

  /**
  * @function fetchTabData
  * @description Fetches data based on the current tab selected in the URL. Different tabs fetch
  * different data (e.g., detail, auditLogs, employeeJobs, etc.).
  * @param {Object} props - The current props of the component.
  */
  fetchTabData(props) {
    const { params } = props.match;
    const {
      location: { search: nextSearch },
    } = props;
    const nextQuery = queryString.parse(nextSearch);
    const pageSize = cookie.load("jobs_page_size");
    // params.id && this.usecase.getEmployeeData(params.id);
    if (params.tab === "detail") {
      this.fetchData(params.id);      // getting called in fetch data
    } else if (params.tab === "auditLogs") {
      this.usecase.getAuditLogs(params.id);
    } else if (params.tab === "kioskAuditLogs") {
      this.usecase.loadKioskList({
        id: params.id,
        start_date: moment(nextQuery.start_date).format("YYYY-MM-DD"),
        end_date: moment(nextQuery.end_date).format("YYYY-MM-DD"),
      });
    } else if (params.tab === "employeeJobs") {
      const unassignJobObj = {
        status: 1,
        admin: true,
        employee_id: params.id,
        assigned: !this.state.assignedStatus,
        paginate: false,
      };
      const assignJobObj = {
        status: 1,
        employee_id: params.id,
        assigned: this.state.assignedStatus,
        search: nextQuery ? nextQuery.search : null,
        page: nextQuery && nextQuery.page ? nextQuery.page : null,
        page_size: pageSize,
        admin: true,
        ordering: "name",
      };
      this.usecase.setup_data1(assignJobObj, unassignJobObj);
      this.usecase.fetchJobs(assignJobObj, unassignJobObj);
    } else if (params.tab === "employeeTimeOff") {
      this.postMessageToChild("REFRESH_EMPLOYEE_TIMEOFF");
    }else if (params.tab === "documents") {
      this.setState({ refrshDocTabFlag: true });
    } else if(params.tab === 'learning-documents'){
      this.setState({refrshLearningDocTabFlag:true});
    } else {
      this.fetchData(params.id);
    }
  }

  /**
  * @function postMessageToChild
  * @description Sends a message to a child iFrame to communicate different message types.
  * @param {string} messageType - The type of message to send to the iFrame.
  */
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame && iFrame.contentWindow.postMessage(messageType, "*");
  }
  
  /**
  * @function getLoginLink
  * @description Fetches the login link for an employee if their ID is present in the URL parameters.
  */
  getLoginLink() {
    const {
      match: { params },
    } = this.props;
    if (params.id) {
      this.usecase.getLoginLink(params.id);
    }
  }

  /**
  * @function fetchData
  * @description Fetches employee data or initial field values based on the presence of employee ID
  * or company ID.
  * @param {string} [employeeId] - Optional employee ID to fetch specific employee data.
  */
  fetchData(employeeId) {
    const {
      match: { params },
      userExtraCompanyData,
    } = this.props;
    const companyId = userExtraCompanyData.id;
    this.usecase.getSystemSettings();
    if (params.id || employeeId) {
      const id = params.id || employeeId;
      this.usecase.getEmployeeDataAndInitialFieldValues(id);
    } else {
      this.usecase.getInitialFieldValues(companyId);
    }
  }

  /**
  * @function setParentUrl
  * @description Sets the parent URL from query parameters if present.
  */
  setParentUrl() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
      // this.setState({ parent });
    }
  }



  /**
  * @function updateState
  * @description A utility function to update the component state for a given key-value pair.
  * @param {string} key - The state key to update.
  * @param {*} value - The new value for the state key.
  */
  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

    /**
   * @function toggle
   * @description Toggles the modal visibility by updating the "showModal" state.
   */
  toggle() {
    const { showModal } = this.state;
    this.updateState("showModal", !showModal);
  }

  /**
  * @function listenEmployeeFormUseCase
  * @description Listens to employee form events and updates the component state or UI accordingly.
  */
  listenEmployeeFormUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "SET_DROPDOWN_VALUES":
          this.updateState("data", event.data);
          break;
        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;
        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        case "SHOW_KIOSK_LOADER":
          this.updateState("isKioskLoading", true);
          break;
        case "HIDE_KIOSK_LOADER":
          this.updateState("isKioskLoading", false);
          break;
        case "SHOW_AUDIT_LOGS_LOADER":
          this.updateState("isAuditLogsLoading", true);
          break;
        case "HIDE_AUDIT_LOGS_LOADER":
          this.updateState("isAuditLogsLoading", false);
          break;
        case "KIOSK_LIST":
          this.updateState("kioskList", event.kioskList);
          break;
        case "SET_SELECTED_ITEM":
          this.updateState("selectedItem", event.selectedItem);
          break;
        case "SHOW_TOAST":
          if (event.toastType === "SUCCESS")
            return toast.success(event.message);
          return toast.error(event.message);
        case "ADD_NEW":
          this.props.history.push(
            `/employee/add/${this.props.location.search}`
          );
          break;
        case "LIST_VIEW":
          {
            const query = queryString.parse(this.props.location.search);
            this.props.history.push({
              pathname: `/employee/${event.data}/edit/detail`,
              search: queryString.stringify(query),
            });
          }
          break;
        case "GET_LINK_SUCCESS":
          this.updateState("loginLink", event.login_url);
          this.toggle();
          break;
        case "SYSTEM_SETTINGS":
          this.updateState("settingsList", event.data);
          break;
        case "AUDIT_LIST":
          this.updateState("auditLogsList", event.auditLogsList);
          break;
        case "SET_EMPLOYEE_DATA":
          this.updateState("employeeData", event.data);
          break;
        case "SHOW_ASSIGNED_JOBS":
          this.updateState("assignedJobs", event.data.results);
          this.updateState("assignedJobscount", event.data.count);
          break;
        case "SHOW_UNASSIGNED_JOBS":
          this.updateState("unAssignedJobs", event.unassignedJobs);
          break;
        case "DELETE_SUCCESS":
          toast.success("Job has been deleted successfully!!!");
          break;
        case "ASSIGN_SUCCESS":
          //this.usecase.getAssignedJobs(assignJobObj);
          //this.usecase.getUnassignedJobs(unassignJobObj);
          toast.success("Job has been assigned successfully!!!");
          break;
        case "HIDE_BUTTON_LOADER":
          this.updateState("isButtonLoading", false);
          break;
        case "SHOW_BUTTON_LOADER":
          this.updateState("isButtonLoading", true);
          break;
        case "SET_TAGS_LIST":
          this.updateState("employeeTagsList", event.data);
          break;
        case "NEW_TAG_ADDED":
          toast.success("New tag created successfully");
          break;
        case "TAGS_DELETED":
          toast.success("Selected tags deleted successfully");
          break;
        case "SET_POLICIES_LIST":
          this.updateState("companyPoliciesList", event.data);
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  /**
  * @function toggleInfo
  * @description Toggles the visibility of employee information based on edit or add mode.
  */
  toggleInfo() {
    const {
      match: { params },
    } = this.props;
    const { isEditInfoOpen, isAddInfoOpen } = this.state;
    if (params.id) {
      this.updateState("isEditInfoOpen", !isEditInfoOpen);
      cookie.save("employee_edit_form_info", !isEditInfoOpen);
    } else {
      this.setState("isAddInfoOpen", !isAddInfoOpen);
      cookie.save("employee_add_form_info", !isAddInfoOpen);
    }
  }

  /**
  * @function switchTabs
  * @param {string} key - Tab identifier to switch between different tabs.
  * @description Switches between different tabs based on the provided key and updates the URL.
  */
  switchTabs(key) {
    const {
      match: { params },
      history,
      location: { search },
    } = this.props;

    if (key === "kioskAuditLogs") {
      const query = queryString.parse(search);
      const newQueries = {
        ...query,
        start_date: moment()
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      return history.push({
        pathname: `/employee/${params.id}/edit/${key}`,
        search: queryString.stringify(newQueries),
      });
    }
    return history.push(`/employee/${params.id}/edit/${key}${search}`);
  }

  /**
  * @function refresh
  * @description Refreshes the data by calling fetchTabData with the current props.
  */
  refresh() {
    this.fetchTabData(this.props);
  }

  /**
  * @function deleteEmployeeJob
  * @param {object} job - Job object to be deleted.
  * @description Deletes an employee's job and updates the URL to reflect the change.
  */
  deleteEmployeeJob(job) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);
    const deleteJobObj = {
      assigned: !this.state.assignedStatus,
      employee_ids: [params.id],
      id: job.id,
    };
    this.usecase.deleteJob(deleteJobObj).then(() => {
      const { page, ...rest } = query;
      history.push({
        pathname,
        search: queryString.stringify({ ...rest, page: 1 }),
      });
    });
  }

  /**
  * @function formatPhoneNumber
  * @param {string|number} value - Phone number to be formatted.
  * @description Formats the phone number into a standard format (XXX) XXX-XXXX.
  */
  formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.toString().replace(/[^\d]/g, ""); //replace all characters except numbers
    const phoneNumberLength = phoneNumber.length;

    this.setState({ phoneNumberPlain: phoneNumber.slice(0, 10) });

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }


  render() {
    const {
      match: { params },
      isOrganizationVisible,
      companyPermissions,
      isSubCompany,
      userExtraCompanyData,
      dateFormat,
      dateFormatDisplay,
      location: { search },
    } = this.props;
    const {
      isLoading,
      employeeData,
      data: { infoList },
      parent,
      isAddInfoOpen,
      isEditInfoOpen,
      selectedItem,
      showModal,
      loginLink,
      assignedJobs,
      unAssignedJobs,
      assignedJobscount,
      isButtonLoading,
      refrshLearningDocTabFlag,
      refrshDocTabFlag,
      policyId,
      policyName,
      ptoWarningMessage,
      ptoWarningModal,
    } = this.state;
    const title =
      employeeData && params.id && Object.keys(employeeData).length
        ? employeeData.name()
        : NAV.EmployeeEditFormPage.title;
    const query = queryString.parse(search);
    const formattedDates = {};
    if (query.start_date)
      formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date)
      formattedDates.end_date = moment(query.end_date).toISOString();
    const employeeRole = this.state.data.roleList.length
      ? this.state.data.roleList.find((i) => i.name === "Employee")
      : {};
    const companyId = userExtraCompanyData.id;
    const phoneMandatory = this.state.settingsList.length
      ? this.state.settingsList.find(
          (i) => i.key.key_code === "MANDATORY_PHONE_NUMBER_ON_EMPLOYEE_PROFILE"
        )
      : null;
    let tabs = [...TABS];
    if (
      (employeeData && employeeData.kiosk_user) ||
      (employeeData && employeeData.dual_login)
    ) {
      tabs[2] = { key: "kioskAuditLogs", label: "Kiosk Audit Logs" };
      tabs[3] = { key: "employeeJobs", label: "Employee Jobs" };
    } else {
      tabs[2] = { key: "employeeJobs", label: "Employee Jobs" };
    }

    if (
      (employeeData && employeeData.kiosk_user) ||
      (employeeData && employeeData.dual_login)
    ) {
      tabs[2] = { key: "kioskAuditLogs", label: "Kiosk Audit Logs" };
      tabs[3] = { key: "employeeJobs", label: "Employee Jobs" };
    } else {
      tabs[2] = { key: "employeeJobs", label: "Employee Jobs" };
    }
    if (companyPermissions && companyPermissions.paid_time_off) {
      tabs = [...tabs, { key: "employeeTimeOff", label: "Employee Time Off" }];
    }
    if (companyPermissions && companyPermissions?.communication_center) {
      tabs = [...tabs, { key: "documents", label: "Documents" }];
    }
    if(companyPermissions && companyPermissions?.learning_center){
      tabs =[...tabs,{ key: "learning-documents", label: "Learning Documents" }]
    }

    return (
      <div className={`${styles["wrapTimecardDetails"]}`}>
        <div
          className={
            this.state.addTimeOffSlider ||
            this.state.timeOffRequestSlider ||
            this.state.historySlider
              ? `${styles["showEditModal"]} abc`
              : `${styles["hideModal"]} abc`
          }
        >
          <TwoColumnLayout
            navInfo={
              params.id
                ? {
                    ...NAV.EmployeeEditFormPage,
                    title,
                    description: EmployeeSubheader(
                      this.state.employeeData,
                      this.formatPhoneNumber
                    ),
                    parent,
                  }
                : { ...NAV.EmployeeAddFormPage, parent }
            }
            fetchData={this.refresh}
            toggleInfo={this.toggleInfo}
            infoList={infoList}
            isInfoOpen={params.id ? isEditInfoOpen : isAddInfoOpen}
            isLoading={isLoading}
          >
            {params.id && (
              <div className="mt-4 ml-3 mr-4">
                <TabsComponent
                  tabItems={tabs}
                  active={params.tab}
                  onClick={this.switchTabs}
                />
              </div>
            )}
            {(params.tab === "detail" || !params.id) && (
              <Row className="ml-3 mr-4">
                {isLoading ? (
                  <SectionLoaderAtom active />
                ) : (
                  <Col md={6} className="col-min-height px-0">
                    <EmployeeForm
                      data={this.state.data}
                      usecase={this.usecase}
                      isOrganizationVisible={isOrganizationVisible}
                      companyPermissions={companyPermissions}
                      isSubCompany={isSubCompany}
                      getLoginLink={this.getLoginLink}
                      employeeTagsList={this.state.employeeTagsList}
                      companyPoliciesList={this.state.companyPoliciesList}
                      params={params}
                      phoneFieldMandatory={
                        phoneMandatory &&
                        phoneMandatory.key &&
                        phoneMandatory.value === "True"
                      }
                      initialValues={
                        params.id
                          ? employeeData
                          : {
                              employee_role: employeeRole && employeeRole.id,
                              company: companyId,
                              is_active: true,
                              phone_ext: userExtraCompanyData.phone_ext,
                            }
                      }
                      isLoading={isLoading}
                      phoneNumberPlain={this.state.phoneNumberPlain}
                      formatPhoneNumber={this.formatPhoneNumber}
                      dateFormat={dateFormat}
                      dateFormatDisplay={dateFormatDisplay}
                      updateState={this.updateState}
                    />
                  </Col>
                )}
              </Row>
            )}
            {params.tab === "kioskAuditLogs" && (
              <div>
                <EmployeeKioskTableFilterComponent
                  location={this.props.location}
                  history={this.props.history}
                  dateFormat={dateFormat}
                  usecase={this.usecase}
                  data={this.state.kioskList}
                  id={params.id}
                  initialValues={formattedDates}
                />
                <KioskTable
                  data={this.state.kioskList}
                  usecase={this.usecase}
                  selectedItemId={selectedItem && selectedItem.id}
                  isKioskLoading={this.state.isKioskLoading}
                  employeeData={employeeData}
                />
              </div>
            )}
            {params.tab === "employeeJobs" && (
              <div>
                <EmployeeJobsFilterFormComponent
                  location={this.props.location}
                  history={this.props.history}
                  usecase={this.usecase}
                  unAssignedJobs={unAssignedJobs}
                  params={params}
                  assignJobToEmployee={this.assignJobToEmployee}
                  isButtonLoading={isButtonLoading}
                  isLoading={isLoading}
                  assignedJobscount={assignedJobscount}
                />
                <EmployeeJobsTableComponent
                  assignedJobs={assignedJobs}
                  deleteEmployeeJob={this.deleteEmployeeJob}
                  isLoading={isLoading}
                />
                {assignedJobscount && !isLoading ? (
                  <PaginationComponent
                    count={assignedJobscount}
                    location={this.props.location}
                    cookieKey="jobs_page_size"
                    history={this.props.history}
                  />
                ) : null}
              </div>
            )}
            {params.tab === "auditLogs" && (
              <AuditTable
                data={this.state.auditLogsList}
                isAuditLogsLoading={this.state.isAuditLogsLoading}
              />
            )}
            {showModal && (
              <ActivationLinkModal
                isOpen={showModal}
                toggle={this.toggle}
                loginLink={loginLink}
              />
            )}
            {params.tab === "employeeTimeOff" && (
              <EmployeeTimeOffTable
                employeeId={params.id}
                employeeName={employeeData.first_name}
                closeSlider={this.closeSlider}
                toggleTimeOffRequestSlider={this.toggleTimeOffRequestSlider}
                toggleHistorySlider={this.toggleHistorySlider}
                history={this.props.history}
                updateState={this.updateState}
                employeeTimeOffRefresh={this.state.employeeTimeOffRefresh}
                postMessageToChild={this.postMessageToChild}
                toggleViewTimeOffPoliciesSlider={
                  this.toggleViewTimeOffPoliciesSlider
                }
              />
            )}
            {params.tab === "documents" && (
              <DocumentsTab
                employeeId={params.id}
                refrshFlag={refrshDocTabFlag}
              />
            )}
            {params.tab === "learning-documents" && (
              <LearningDocumentsTab
                employeeId={params.id}
                refrshFlag={refrshLearningDocTabFlag}
              />
            )}
          </TwoColumnLayout>
        </div>

        {this.state.addTimeOffSlider===false && ptoWarningModal === true &&
            <PtoWarningModal 
              isOpen={ptoWarningModal}
              message={ptoWarningMessage}
              toggle={this.closePtoWarningModal}
              handleSubmit={this.handleDoNotShowMsgPto}
            />
          }

        <div style={{ width: "35%" }}>
          {this.state.addTimeOffSlider ? (
            <AddTimeOffSlider
              oAuthToken={oAuthToken}
              company_id={company_id}
              closeSlider={this.closeSlider}
              typeOfPage="employeeEdit"
              employeeId={params.id}
              employeeName={employeeData.first_name}
            />
          ) : null}
          {this.state.timeOffRequestSlider ? (
            <TimeOffRequestSlider
              oAuthToken={oAuthToken}
              company_id={company_id}
              employeeName={employeeData.first_name}
              policyId={this.state.policyId}
              employeeId={params.id}
              typeOfPage="employeeEdit"
              closeSlider={this.toggleTimeOffRequestSlider}
            />
          ) : null}
        </div>
          {this.state.toggleEmployeeNotes ? (
            <LayerAtom
              active={true}
              heading={"Employees Notes"}
              path={`${process.env.REACT_APP_WMAPPER_BASE_URL}/employee/notes?access_token=${nre_token}&user_id=${userID}&employee_id=${params.id}&companyId=${company_id||nre_company_id}`}
              setToggle={() => this.setState({toggleEmployeeNotes: false})}
            />
          ) : null}
          {this.state.viewTimeOffPoliciesSlider ? (
            <LayerAtom
              active={true}
              heading={"View Time Off Policies"}
              path={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-policy/employee-edit/timeOff-policies/?oAuthToken=${oAuthToken}&company_id=${company_id}&employee_id=${params.id}`}
              setToggle={() => this.toggleViewTimeOffPoliciesSlider(false)}
            />
          ) : null}
          {this.state.historySlider ? (
            <LayerAtom
              active={true}
              heading={`History : ${policyName}`}
              backButtonFlag={true}
              path={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-policy/employee-edit/timeOff-policies/?oAuthToken=${oAuthToken}&company_id=${company_id}&view_timeOff_policies=true&policy_id=${policyId}&employee_id=${params.id}&policy_type=${this.state.policyType}`}
              setToggle={() => this.toggleHistorySlider({status:false,policy_id:'',policy_name:''})}
            />
          ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userExtraCompanyData: UserDucks.userExtraCompanyData(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  companyPermissions: UserDucks.companyPermissions(state),
  isSubCompany: UserDucks.isSubCompany(state),
  dateFormatDisplay: BasicSettingDucks.dateFormatDisplay(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  UserDucks: bindActionCreators(UserDucks, dispatch),

});

EmployeeFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeFormPage);
