import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { toast } from "react-toastify";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";

import { Button, Col, Form, Row } from "reactstrap";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as UserDucks from "ducks/accounts/user";
import * as JobDucks from "ducks/jobs/job";
import SectionLoaderAtom from "atoms/SectionLoader";

import * as JobEmployeeDucks from "ducks/jobs/jobEmployeeMapping";
import * as RouteDucks from "ducks/routes";
import * as CustomerDucks from "ducks/customers/customer";
import * as JobTaskDucks from "ducks/jobs/jobTaskMapping";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as EmployeeDucks from "ducks/employees/employee";
import * as DepartmentDucks from "ducks/vendors/department";
import * as TeamDucks from "ducks/vendors/team";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import InputAtom from "atoms/Input";
import { withRouter } from "react-router-dom";

import SelectAtom from "atoms/Select";
import { FORM_LAYOUT } from "constants/layout";
import { WEEKDAYS_IN_SHORT } from "constants/jobs";
import styles from "sections/jobs/jobEmployeeEdit/styles.module.scss";

const MEAL_TYPE_LIST = [
  { value: "PAID", label: "Paid" },
  { value: "UNPAID", label: "Unpaid" },
];

const OT_ACCURALS = [
  { value: "YES", label: "Yes" },
  { value: "NO", label: "No" },
];

const ADJUSTMENT_TYPES = [
  { value: "regular_time", label: "RT" },
  { value: "over_time", label: "OT" },
  { value: "double_over_time", label: "DOT" },
];

class MealSettingsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      weekDays: WEEKDAYS_IN_SHORT,
      disable: false,
      createJob: false,
      nextPage: false,
      err: {},
    };
    this.submit = this.submit.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
  }

  goToUrl(url) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    if (query.setup) {
      history.push({
        pathname: `${url}`,
        search: queryString.stringify({ ...query, setup: true }),
      });
    } else {
      history.push({
        pathname: `${url}`,
        search: queryString.stringify({ ...query }),
      });
    }
  }

  async submit(data) {
    const {
      location: { search },
      selectedId,
      userExtraData,
      mealTypes,
      history,
      is_active,
    } = this.props;

    const company_id = userExtraData?.company?.id;

    let obj = {
      company_id: company_id,
      id: selectedId,
    };

    if (is_active === false) {
      history.push({
        pathname: `/vendor/meal-adjustments/${selectedId}/employeeSelection`,
        search: queryString.stringify({
          ...queryString.parse(search),
          setup: true,
        }),
      });
      return;
    }

    if (mealTypes === "AUTOMATIC_MEAL_DEDUCT") {
      obj.rule_configuration = {
        meal_duration_hours: parseInt(data.meal_duration_hours, 10),
        meal_duration_minutes: parseInt(data.meal_duration_minutes, 10),
        meal_start_after_hours: parseInt(data.after_hours, 10),
        meal_start_after_minutes: parseInt(data.after_minutes, 10),
        shift_duration_threshold_hours: parseInt(data.shift_duration_hours, 10),
        shift_duration_threshold_minutes: parseInt(
          data.shift_duration_minutes,
          10
        ),
        is_meal_paid: data.meal_type?.value || data?.meal_type,
        count_towards_ot_accural: data.accurals?.value || data?.accurals,
      };
    } else {
      obj.rule_configuration = {
        adjustment_duration_hours: parseInt(data.adjustment_duration_hours, 10),
        adjustment_duration_minutes: parseInt(
          data.adjustment_duration_minutes,
          10
        ),
        shift_duration_threshold_minutes: parseInt(
          data.shift_duration_minutes,
          10
        ),
        shift_duration_threshold_hours: parseInt(data.shift_duration_hours, 10),
        adjustment_type: data?.adjustment_type?.value || data?.adjustment_type,
      };
    }

    this.setState({ nextLoader: true });

    try {
      const res = await this.props.putMealTypes(obj);
      if (res && res.value && res.value.id) {
        toast.success("Meal successfully created!");
        history.push({
          pathname: `/vendor/meal-adjustments/${res.value.id}/employeeSelection`,
          search: queryString.stringify({
            ...queryString.parse(search),
            setup: true,
          }),
        });
      } else {
        toast.error(
          "Failed to create meal due to unexpected response structure!"
        );
      }
    } catch (e) {
      toast.error(e.non_field_errors[0]);
    } finally {
      this.setState({ nextLoader: false });
    }
  }


  render() {
    const {
      handleSubmit,
      mealTypes,
      selectedId,
      is_active,
      employees,
      errors,
    } = this.props;
    const { nextLoader } = this.state;

    return (
      <Form onSubmit={handleSubmit(this.submit)} className="job-detail">
        {mealTypes === "AUTOMATIC_MEAL_DEDUCT" ? (
          <>
            <Row style={{marginBottom:"1rem"}}>
              Meal Duration:
              <Col md={1}>
                <Field
                  id="meal_duration_hours"
                  name="meal_duration_hours"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
              Hours
              <Col md={1}>
                <Field
                  id="meal_duration_minutes"
                  name="meal_duration_minutes"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={
                    is_active === false ||
                    employees?.length > 0 ||
                    errors?.meal_duration_hours
                  }
                />
              </Col>
              Mins
            </Row>

            <Row style={{marginBottom:"1rem"}}>
              After:
              <Col md={1}>
                <Field
                  id="after_hours"
                  name="after_hours"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
              Hours
              <Col md={1}>
                <Field
                  id="after_minutes"
                  name="after_minutes"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={
                    is_active === false ||
                    employees?.length > 0 ||
                    errors?.after_hours
                  }
                />
              </Col>
              Mins
            </Row>

            <Row style={{marginBottom:"1rem"}}>
              Shift Duration Threshold:
              <Col md={1}>
                <Field
                  id="shift_duration_hours"
                  name="shift_duration_hours"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
              Hours
              <Col md={1}>
                <Field
                  id="shift_duration_minutes"
                  name="shift_duration_minutes"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={
                    is_active === false ||
                    employees?.length > 0 ||
                    errors?.shift_duration_hours
                  }
                />
              </Col>
              Mins
            </Row>

            <Row style={{marginBottom:"1rem"}}>
              Meal Type:
              <Col md={3}>
                <Field
                  id="meal_type"
                  name="meal_type"
                  component={SelectAtom}
                  options={MEAL_TYPE_LIST}
                  labelKey="label"
                  valueKey="value"
                  layout={FORM_LAYOUT}
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
            </Row>
            <Row style={{marginBottom:"1rem"}}>
              Counts towards OT Accurals:
              <Col md={3}>
                <Field
                  id="accurals"
                  name="accurals"
                  component={SelectAtom}
                  options={OT_ACCURALS}
                  labelKey="label"
                  valueKey="value"
                  layout={FORM_LAYOUT}
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row style={{marginBottom:"1rem"}}>
              Adjustment Duration:
              <Col md={1}>
                <Field
                  id="adjustment_duration_hours"
                  name="adjustment_duration_hours"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
              Hours
              <Col md={1}>
                <Field
                  id="adjustment_duration_minutes"
                  name="adjustment_duration_minutes"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={
                    is_active === false ||
                    employees?.length > 0 ||
                    errors?.adjustment_duration_hours
                  }
                />
              </Col>
              Mins
            </Row>

            <Row style={{marginBottom:"1rem"}}>
              Shift Duration Threshold:
              <Col md={1}>
                <Field
                  id="shift_duration_hours"
                  name="shift_duration_hours"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
              Hours
              <Col md={1}>
                <Field
                  id="shift_duration_minutes"
                  name="shift_duration_minutes"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  maxLength="50"
                  changeStyle={true}
                  disabled={
                    is_active === false ||
                    employees?.length > 0 ||
                    errors?.shift_duration_hours
                  }
                />
              </Col>
              Mins
            </Row>
            <Row style={{marginBottom:"1rem"}}>
              Type:
              <Col md={3}>
                <Field
                  id="adjustment_type"
                  name="adjustment_type"
                  component={SelectAtom}
                  options={ADJUSTMENT_TYPES}
                  labelKey="label"
                  valueKey="value"
                  layout={FORM_LAYOUT}
                  changeStyle={true}
                  disabled={is_active === false || employees?.length > 0}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={12} className="mt-2 mb-3 pr-0 text-right">
            <Button
              color="primary"
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
              className={`${styles["btn_accent_width"]} btn-accent`}
              onClick={() =>
                this.goToUrl(
                  `/vendor/meal-adjustments/${selectedId}/mealDetails`
                )
              }
            >
              Previous <ButtonLoaderAtom />
            </Button>
            <Button
              color="primary"
              className={`${styles["btn_accent_width"]} btn-accent`}
              type="submit"
            >
              Next <ButtonLoaderAtom active={nextLoader} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (props.is_active === true) {
    const isDisabled = (fieldName) =>
      props.fields?.[fieldName]?.disabled || props.disabledFields?.includes(fieldName);



  if (props.is_active === true) {
    if (values?.meal_duration_hours === "") {
      errors.meal_duration_hours = "Meal duration hours is required";
    }
    if (isDisabled("meal_duration_minutes") && values?.meal_duration_minutes === "" && !errors.meal_duration_hours) {
      errors.meal_duration_minutes = "Meal duration minutes is required";
    }
    if (values?.after_hours === "") {
      errors.after_hours = "After hours is required";
    }
    if (isDisabled("after_minutes") && values?.after_minutes === "" && !errors.after_hours) {
      errors.after_minutes = "After minutes is required";
    }
    if (values?.shift_duration_hours === "") {
      errors.shift_duration_hours = "Shift duration Threshold hours is required";
    }
    if (isDisabled("shift_duration_minutes") && values?.shift_duration_minutes === "" && !errors.shift_duration_hours) {
      errors.shift_duration_minutes = "Shift duration Threshold minutes is required";
    }

    if (
      values?.meal_duration_hours !== "" &&
      !/^(0|[1-9][0-9]*)$/.test(values.meal_duration_hours)
    ) {
      errors.meal_duration_hours =
        "Meal Duration hours must be a number between 0 and 9";
    }
    if (!values?.meal_duration_hours && !errors.meal_duration_hours && 
      values?.meal_duration_minutes !== "" &&
      !/^([1-9][0-9]*)$/.test(values.meal_duration_minutes)
    ) {
      errors.meal_duration_minutes =
        "Meal Duration minutes must be a number greater than 0 ";
    }
    if (values?.meal_duration_hours === "0" && 
      (values?.meal_duration_minutes !== "" || values?.meal_duration_minutes === "") &&
      !/^([1-9][0-9]*)$/.test(values.meal_duration_minutes)
    ) {
      errors.meal_duration_minutes =
        "Meal Duration minutes must be a number greater than 0 ";
    }
    if (values?.meal_duration_hours !== "0" && !errors.meal_duration_hours && 
      values?.meal_duration_minutes !== 0 &&
      !/^(0|[1-9][0-9]*)$/.test(values.meal_duration_minutes)
    ) {
      errors.meal_duration_minutes =
        "Meal Duration minutes must be a number greater than or equal to 0";
    }
   

    if (
      values?.after_hours !== "" &&
      !/^(0|[1-9][0-9]*)$/.test(values.after_hours)
    ) {
      errors.after_hours = "After hours must be a number between 0 and 9";
    }
    if (!values?.after_hours && !errors.after_hours &&
      values?.after_minutes !== "" &&
      !/^([1-9][0-9]*)$/.test(values.after_minutes)
    ) {
      errors.after_minutes = "After minutes must be a number greater than 0 ";
    }
    if (values?.after_hours === "0" &&
      (values?.after_minutes !== "" || values?.after_minutes === "" )&&
      !/^(0|[1-9][0-9]*)$/.test(values.after_minutes)
    ) {
      errors.after_minutes = "After minutes must be a number greater than 0 ";
    }
    if (values?.after_hours !== "0" && !errors.after_hours && 
      values?.after_minutes !== 0 &&
      !/^(0|[1-9][0-9]*)$/.test(values.after_minutes)
    ) {
      errors.after_minutes =
        "After minutes must be a number greater than or equal to 0";
    }


    if (
      values?.shift_duration_hours !== "" &&
      !/^(0|[1-9][0-9]*)$/.test(values.shift_duration_hours)
    ) {
      errors.shift_duration_hours =
        "Shift Duration Threshold hours must be a number between 0 and 9";
    }
    if (!values?.shift_duration_hours && !errors.shift_duration_hours &&
      values?.shift_duration_minutes !== "" &&
      !/^([1-9][0-9]*)$/.test(values.shift_duration_minutes)
    ) {
      errors.shift_duration_minutes =
        "Shift Duration Threshold minutes must be a number greater than 0 ";
    }
    if (values?.shift_duration_hours === "0" &&
      (values?.shift_duration_minutes !== "" || values?.shift_duration_minutes === "") &&
      !/^([1-9][0-9]*)$/.test(values.shift_duration_minutes)
    ) {
      errors.shift_duration_minutes =
        "Shift Duration Threshold minutes must be a number greater than 0 ";
    }
    if (values?.shift_duration_hours !== "0" && !errors.shift_duration_hours && 
      values?.shift_duration_minutes !== 0 &&
      !/^(0|[1-9][0-9]*)$/.test(values.shift_duration_minutes)
    ) {
      errors.shift_duration_minutes =
        "Shift Duration Threshold minutes must be a number greater than or equal to 0";
    }


    if (values?.adjustment_duration_hours === "") {
      errors.adjustment_duration_hours =
        "Adjustment duration hours is required";
    }
    if (isDisabled("adjustment_duration_minutes") && values?.adjustment_duration_minutes === "" && !errors.adjustment_duration_hours) {
      errors.adjustment_duration_minutes =
        "Adjustment duration minutes is required";
    }


    if (
      values?.adjustment_duration_hours !== "" &&
      !/^(0|[1-9][0-9]*)$/.test(values.adjustment_duration_hours)
    ) {
      errors.adjustment_duration_hours =
        "Adjustment Duration hours must be a number between 0 and 9";
    }
    if (!values?.adjustment_duration_hours && !errors.adjustment_duration_hours &&
      values?.adjustment_duration_minutes !== "" &&
      !/^([1-9][0-9]*)$/.test(values.adjustment_duration_minutes)
    ) {
      errors.adjustment_duration_minutes =
        "Adjustment Duration minutes must be a number greater than 0 ";
    }
    if (values?.adjustment_duration_hours === "0" &&
      (values?.adjustment_duration_minutes !== "" || values?.adjustment_duration_minutes === "" ) &&
      !/^([1-9][0-9]*)$/.test(values.adjustment_duration_minutes)
    ) {
      errors.adjustment_duration_minutes =
        "Adjustment Duration minutes must be a number greater than 0 ";
    }
    if (values?.adjustment_duration_hours !== "0" && !errors.adjustment_duration_hours && 
      values?.adjustment_duration_minutes !== 0 &&
      !/^(0|[1-9][0-9]*)$/.test(values.adjustment_duration_minutes)
    ) {
      errors.adjustment_duration_minutes =
        "Adjustment duration minutes must be a number greater than or equal to 0";
    }



    if (!(values?.meal_type || values?.meal_type?.value)) {
      errors.meal_type = "Meal type is required";
    }
    if (!(values?.accurals || values?.accurals?.value)) {
      errors.accurals = "OT accural is required";
    }
   

    if (!(values?.adjustment_type || values?.adjustment_type?.value)) {
      errors.adjustment_type = "Adjustment type is required";
    }

    props.update(errors);
  }
    return errors;
  }
};

const mapStateToProps = (state) => ({
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  location: RouteDucks.location(state),
  customerList: CustomerDucks.customerList(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  employeeList: EmployeeDucks.employeeList(state),
  allEmployeeList: EmployeeDucks.employeeAll(state),
  tagsList: EmployeeDucks.tagsList(state),
});

const mapActionToProps = (dispatch) => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  JobEmployeeDucks: bindActionCreators(JobEmployeeDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  JobTaskDucks: bindActionCreators(JobTaskDucks, dispatch),
  putMealTypes: bindActionCreators(AdjustmentTypesDucks.putMealTypes, dispatch),
  getMealTypes: bindActionCreators(AdjustmentTypesDucks.getMealTypes, dispatch),
});

MealSettingsForm.defaultProps = {
  location: {},
  history: {},
  initialValues: {
    meal_duration_hours: 0,
    meal_duration_minutes: 0,
    after_hours: 0,
    after_minutes: 0,
    shift_duration_hours: 0,
    shift_duration_minutes: 0,
    meal_type: {},
    accurals: {},
    adjustment_duration_hours: 0,
    adjustment_duration_minutes: 0,
    adjustment_type: {},
  },
};

MealSettingsForm.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tagList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
  customerList: PropTypes.array.isRequired,
  change: PropTypes.func,
  initialValues: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  toggleTagModal: PropTypes.func,
};

MealSettingsForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withReduxForm = reduxForm({
  form: "MealSettingForm",
  validate,
  enableReinitialize: true,
})(MealSettingsForm);

export default withRouter(
  connect(mapStateToProps, mapActionToProps)(withReduxForm)
);
